<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="row mx-0 w-100 gy-4">
    <div class="col-12" [ngClass]="{ 'text-center': !isLogged }">
      <h3 class="mb-0">Información personal</h3>
    </div>

    <div class="col-12 p-0" *ngIf="isLogged">
      <div class="row m-0">
        <div class="col-auto mb-3 mb-lg-0">
          <div class="picture-selector" (click)="fileInput.click()">
            <div class="picture border-primary">
              <img
                [src]="data.avatar_url"
                alt="Logo amm"
                width="100%"
                onerror="this.onerror=null; this.src='/assets/images/avatar.png'"
              />
            </div>

            <div class="icon-sel">
              <i class="fa-solid fa-pen-circle fa-xl"></i>
            </div>
          </div>
        </div>

        <div class="col">
          <h3 class="text-center mb-0">
            {{ name + " " + data.last_name }}
          </h3>
        </div>

        <div class="w-100"></div>

        <div class="col-8 mt-4 col-lg-auto">
          <button class="btn btn-primary btn-amm" (click)="fileInput.click()">
            MODIFICAR FOTO
          </button>
        </div>

        <input
          #fileInput
          type="file"
          hidden
          (change)="selectFile($event)"
          accept="image/png, image/jpeg, .png, .jpg"
        />
      </div>
    </div>

    <div class="col-12">
      <hr />
    </div>
  </div>

  <div class="row mx-0 w-100 gy-4 mb-4">
    <div class="col-12" *ngIf="isLogged">
      <input
        type="email"
        class="form-control input-amm"
        [value]="data.email"
        [disabled]="true"
        [readonly]="true"
      />
    </div>

    <div class="col-12">
      <span class="required">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.name"
          placeholder="Nombre(s)"
          validationdirective
          [required]="true"
        />
      </span>
    </div>

    <div class="col-12">
      <span class="required">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="lastname1"
          placeholder="Apellido paterno"
          validationdirective
          [required]="true"
        />
      </span>
    </div>

    <div class="col-12">
      <span class="required">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="lastname2"
          placeholder="Apellido materno"
          validationdirective
          [required]="true"
        />
      </span>
    </div>

    <div class="col-12">
      <span class="required">
        <ng-select
          [items]="prefix"
          placeholder="Prefijo"
          [(ngModel)]="data.job_title"
          [clearable]="false"
          validationdirective
          [required]="true"
        ></ng-select>
      </span>
    </div>

    <div class="col-12">
      <span class="required">
        <ng-select
          [items]="genders"
          bindLabel="name"
          bindValue="id"
          placeholder="Sexo"
          [(ngModel)]="data.gender"
          [clearable]="false"
        ></ng-select>
      </span>
    </div>

    <div class="col-12">
      <label for="birthdate" class="mb-2"> Fecha de nacimiento </label>

      <span class="required">
        <input
          type="date"
          id="birthdate"
          class="form-control input-amm"
          [(ngModel)]="data.birthdate"
          validationdirective
          [required]="true"
        />
      </span>
    </div>
  </div>

  <div class="row mx-0 w-100 mb-4" [ngClass]="{ 'gy-4': !isLogged }">
    <div class="col-12" *ngIf="!isLogged">
      <h3 class="mb-0">Información profesional</h3>
    </div>

    <div class="col-12" *ngIf="specialities && specialities.length > 0">
      <label for="specialities"> Especialidad </label>

      <span class="required">
        <ng-select
          id="specialities"
          [items]="specialities"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="data.speciality_id"
          [clearable]="false"
          placeholder="Elegir"
        ></ng-select>
      </span>

      <p class="mt-2" *ngIf="!isLogged && site === 'ammom'">
        <span class="text-warning">*</span> Si usted no encuentra su
        especialidad favor de contactar a AMMOM (<a
          href="mailto:educacion@ammom.mx"
          >educacion@ammom.mx</a
        >)
      </p>
    </div>

    <div class="col-12" *ngIf="!isLogged">
      <label for="professional" class="mb-2"> Cédula profesional </label>

      <input
        id="professional"
        type="text"
        class="form-control input-amm mt-2"
        placeholder="Número"
        [(ngModel)]="data.professional_number"
      />
    </div>
  </div>

  <div class="row mx-0 w-100 gy-4 mb-4">
    <div class="col-12">
      <h3 class="mb-0">Datos de contacto</h3>
    </div>

    <div class="col-12">
      <label for="phone">Teléfono móvil</label>

      <span class="required">
        <input
          id="phone"
          type="text"
          maxdigits
          class="form-control input-amm mt-2"
          placeholder="10 dígitos"
          [(ngModel)]="data.phone_number"
          validationdirective
          [required]="true"
          maxdigits
        />
      </span>
    </div>

    <div class="col-12">
      Teléfono fijo

      <input
        type="tel"
        maxdigits
        class="form-control input-amm mt-2"
        placeholder="10 dígitos"
        [(ngModel)]="data.phone_number2"
        maxdigits
      />
    </div>
  </div>

  <app-address #addressComponent [data]="data"></app-address>

  <div class="row mx-0 w-100 gy-4">
    <div class="col-12 align-items-center">
      <small>
        <strong><span class="text-primary">*</span> Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="handleSubmit()">
        {{ isLogged ? "GUARDAR CAMBIOS" : "SIGUIENTE" }}

        <i class="fa-regular fa-arrow-right ms-1 my-auto" *ngIf="!isLogged"></i>
      </button>
    </div>
  </div>
</div>
