<div class="main-container" [ngClass]="{ loading: loading }">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="container min-container mx-auto py-4 py-lg-5">
    <div class="row mb-4">
      <div
        class="col-12 py-4 image-container"
        [style.backgroundImage]="'url(' + site.image.source + ')'"
      >
        <div class="row w-100 h-100 m-0 py-5">
          <h3 class="col-12">{{ site.image.title }}</h3>

          <h5 class="my-5 col-12 my-auto font-regular">
            {{
              isLogged ? site.image.subtitle : "¡Hemos recibido su solicitud!"
            }}
          </h5>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!isRegister; else noLogged">
      <div
        class="col-12 text-center mt-4"
        *ngIf="site.subtitle || site.messages.length > 0"
      >
        <h3 class="mb-4" *ngIf="site.subtitle">{{ site.subtitle }}</h3>

        <p class="mb-0" *ngFor="let msg of site.messages">
          <span>{{ msg }}</span> <br />
        </p>
      </div>

      <div
        class="col-12 text-center mt-3"
        *ngIf="site.subtitleAction.link && site.subtitleAction.text"
      >
        <ng-template #localLink>
          <a class="amm-link" [routerLink]="site.subtitleAction.link">
            {{ site.subtitleAction.text }}
          </a>
        </ng-template>

        <a
          class="amm-link"
          *ngIf="
            type === 'congress' ||
              type === 'default' ||
              type === 'somos' ||
              type === 'ammom';
            else localLink
          "
          [href]="site.subtitleAction.link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ site.subtitleAction.text }}
        </a>
      </div>
    </div>

    <ng-template #noLogged>
      <app-finish-register [profile]="profile"></app-finish-register>
    </ng-template>

    <div class="row">
      <hr class="col-12 my-5" />

      <h3 class="col-12 text-center">Accesos rápidos</h3>
    </div>

    <div class="row py-4 justify-content-evenly">
      <div
        class="col-5 col-md-4 col-lg-3 text-center mb-3 mb-md-0"
        [ngClass]="{ 'px-0': shortcuts.length < 4 }"
        *ngFor="let shortcut of shortcuts; index as ind"
      >
        <a
          class="shortcut-card text-center m-auto text-decoration-none"
          [ngClass]="
            site.site && ind === 0 ? 'shortcut-' + type + '-active' : ''
          "
          [routerLink]="'/' + shortcut.link"
        >
          <!-- <i class="{{shortcut.icon}} fa-xl"></i> -->
          <img
            [src]="'icons/' + shortcut.image | siteAsset : siteEnum"
            alt=""
          />
          <span [innerHTML]="shortcut.name"></span>
        </a>
      </div>
    </div>
  </div>
</div>
