import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AlertType } from '@models/Alert.model';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';
import { UserService } from '@services/user.service';
import { AMMOM_PREFIX, GENDERS, PREFIX } from './Data';
import { AmmFormComponent } from '@shared/components/amm-form/amm-form.component';
import { Memebership } from '@models/Membership.model';
import { Router } from '@angular/router';
import { ValidationDirective } from '@shared/directives/validation.directive';

import { AddressComponent } from '../../components/address/address.component';
import { SiteService } from '@services/site.service';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent extends AmmFormComponent implements OnInit {
  name: string = '';
  lastname1: string = '';
  lastname2: string = '';
  specialities: any;
  genders: string[] = [...GENDERS];
  prefix: string[] = [];
  isLogged: boolean = false;
  currentTemplate = {
    name: '',
    file: '',
  };
  currentStep: number = 0;
  nextStep: number = 4;
  currentMembership: Memebership = new Memebership();
  override data: Profile = new Profile();
  environment = environment;
  site: SiteEnum = SiteEnum.Amm;

  // For input validation ref
  @ViewChildren(ValidationDirective)
  inputsDirectives!: QueryList<ValidationDirective>;
  @ViewChild('addressComponent') addressComponent?: AddressComponent;

  constructor(
    api: ApiService,
    private user: UserService,
    private router: Router,
    private siteService: SiteService
  ) {
    super(api);
    this.site = this.siteService.site;

    if (siteService.site === SiteEnum.Ammom) {
      this.prefix = [...AMMOM_PREFIX];
    } else {
      this.prefix = [...PREFIX];
    }
  }

  ngOnInit(): void {
    this.isLogged = this.user.logged;
    this.currentStep = this.user.registerStep;

    if (this.isLogged) {
      this.endpoint = 'portal/me/profile';
      this.method = 'put';

      this.api.callReq(
        { service: 'portal/me', method: 'get', params: {} },
        (response) => {
          this.data.setProfile(response.data);
          this.user.profile = response.data;
          this.name = this.data.name;
          this.lastname1 = this.data.last_name.split(' ')[0] ?? '';
          this.lastname2 = this.data.last_name.split(' ')[1] ?? '';

          this.getSpecialities(
            this.site === 'ammom'
              ? `portal/memberships/${this.data.membership_id}/specialities`
              : `specialities?speciality=${this.getMemberType(
                  this.data.membership_id
                )}`
          );
        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );
    } else {
      this.getRegisterData();

      this.endpoint = 'portal/register';
      this.method = 'post';

      this.getSpecialities(
        this.site === 'ammom'
          ? `portal/memberships/${this.getMembershipData()}/specialities`
          : `specialities?speciality=${this.getMemberType(
              this.data.membership_id
            )}`
      );
    }
  }

  getProfile() {
    this.loading = true;

    this.api.callReq(
      { service: 'portal/me', method: 'get', params: {} },
      (response: any) => {
        this.data.setProfile(response.data);
        this.user.profile = response.data;
        this.lastname1 = this.data.last_name.split(' ')[0];
        this.lastname2 = this.data.last_name.split(' ')[1];

        if (this.data.avatar_url === '') {
          this.data.avatar_url = 'assets/images/avatar.png';
        }

        this.loading = false;
      },
      (error: any) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  getSpecialities(service: string) {
    this.api.get(service).subscribe({
      next: (response) => {
        if (response) {
          this.specialities = this.site === 'ammom' ? response.data : response;
        }

        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.loading = false;
      },
    });
  }

  // HANDLE SUBMIT BUTTON
  handleSubmit() {
    this.data.last_name = this.lastname1 + ' ' + this.lastname2;

    if (!this.isValid()) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Llena todos los campos requeridos',
        className: AlertType.Danger,
      };

      this.addressComponent?.validate();

      this.inputsDirectives.forEach((directive: ValidationDirective) => {
        directive.onBlur();
      });

      return;
    }

    if (this.isLogged) {
      this.submit();
    } else {
      this.saveDataRegister();
    }
  }

  override isValid(): boolean {
    if (this.isLogged) {
      const valid = this.data.isValid();
      return valid;
    }

    const valid = this.data.isValidRegister(
      this.currentMembership.id !== environment.memberships.no_profile
    );

    return valid;
  }

  getMembershipData(): number {
    const data = this.user.getRegister('STEP2');

    if (data) {
      if (data.membership_id) {
        return Number(data.membership_id);
      }

      return 3;
    }

    return 3;
  }

  override getParams() {
    if (this.isLogged) return this.data.getProfileToSave();

    const registerData = this.user.getRegister();
    const params = Object.assign(
      {},
      registerData['STEP1'],
      registerData['STEP2'],
      registerData['STEP3']
    );

    return params ?? {};
  }

  override success(response: any): void {
    if (!this.isLogged) {
      this.autoLogin();
      return;
    }

    this.alertData.data = {
      title: '',
      content: '¡Cambios guardados exitosamente.!',
      className: AlertType.Success,
    };

    this.user.profile = response.data;
    this.loading = false;
  }

  autoLogin() {
    const data = this.user.getRegister('STEP1');

    this.loading = true;

    if (data) {
      const email = data.email;
      const password = data.password;

      this.api.callReq(
        {
          service: 'portal/login',
          method: 'post',
          params: { email: email, password: password },
        },
        (response: any) => {
          this.user.token = response.data.token;
          this.user.profile = response.data.user;
          this.user.cleanRegister();
          this.router.navigate(['register/payment']);
          this.loading = false;
        },
        (error: any) => {
          this.alertData.data = {
            title: '¡Error!',
            content: error,
            className: AlertType.Danger,
          };
          this.loading = false;
        }
      );
    } else {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Ocurrio un error revisa tus datos.',
        className: AlertType.Danger,
      };

      this.loading = false;
    }
  }

  // REGISTER STEPS
  getRegisterData() {
    const data = this.user.getRegister('STEP3');

    if (data) {
      this.data.setData(data);
      [this.lastname1, this.lastname2] = data.last_name.split(' ');
    } else {
      this.data = new Profile();
    }

    if (this.user.getRegister('MAINMEMBER'))
      this.currentMembership = this.user.getRegister('MAINMEMBER');
  }

  saveDataRegister() {
    const value = {
      key: 'STEP3',
      value: this.data.getRegisterJSON(),
    };

    this.user.setRegister(value);

    if (
      (this.site === 'ammom' &&
        this.currentMembership.id === environment.memberships.pending) ||
      this.currentMembership.id === environment.memberships.no_profile ||
      this.currentMembership.id === environment.memberships.transitional
    ) {
      this.submit();
      return;
    }

    this.user.registerStep =
      this.nextStep < this.currentStep ? this.currentStep : this.nextStep;
  }
  // REGISTER STEPS
  uploadPhoto() {
    this.api.callReq(
      {
        service: 'portal/me/avatar',
        method: 'put',
        params: { image: this.currentTemplate.file },
      },
      (response: any) => {
        this.getProfile();
      },
      (error: any) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  selectFile(file: Event) {
    if (this.loading) return;

    this.loading = true;
    const target = file.target as HTMLInputElement;
    let template = { name: '', file: '' };

    if (target) {
      template.name = (target.files as FileList)[0].name;
      let fileReader = new FileReader();

      if (target.files && target.files.length > 0) {
        let file = target.files[0];
        fileReader.readAsDataURL(file);

        fileReader.onload = (event: any) => {
          template.file = event.target.result;
          this.data.avatar_url = template.file;
          this.currentTemplate.name = template.name;
          this.currentTemplate.file = template.file;

          if (this.isLogged) {
            this.uploadPhoto();
          } else {
            this.loading = false;
          }
        };
      }
    }

    this.loading = false;
  }

  getMemberType(memberType: number): string {
    if (
      memberType === 10 ||
      memberType === 13 ||
      memberType === 14 ||
      memberType === 12
    ) {
      return '2';
    }
    if (
      memberType === 7 ||
      memberType === 8 ||
      memberType === 11 ||
      memberType === 12
    ) {
      return '1';
    }
    if (memberType === 9) {
      return '0';
    }

    return '0,1';
  }
}
