<div class="sidebar-container">
  <!-- SIDEBAR CONTROL REDIRECT -->
  <app-sidebar
    [title]="isLogged ? 'Cuenta' : 'Nuevo cuenta'"
    [items]="sidebarItems"
    [isLogged]="isLogged"
    [total]="total"
  ></app-sidebar>

  <div class="sidebar-content">
    <div class="app-content px-3 py-4 py-xl-5">
      <div
        class="ms-xl-5"
        [ngClass]="{ 'min-container': step < total || isLogged }"
        *ngIf="!isLogged && step < total"
      >
        <app-register-message
          [step]="step"
          [total]="total"
        ></app-register-message>
      </div>

      <div
        class="mx-xl-auto px-2 px-xl-5"
        [ngClass]="{ 'min-container boxshadow-amm': step < total || isLogged }"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
