import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertModal, AlertType } from '@models/Alert.model';
import { MakePayment, PaymentSubscription } from '@models/Payment.model';
import { Profile } from '@models/Profile.model';
import { HotelReservation } from 'src/app/congress/models/Hotel.model';

import { ApiService } from '@services/api.service';
import { Storage } from '@services/storage.service';
import { UserService } from '@services/user.service';

import { CardComponent } from '../../components/stripe-card/stripe-card.component';
import { BillFormComponent } from '../../components/bill-form/bill-form.component';

import { CongressKeysEnum } from '@enums/CongressKeys.enum';
import { PaymentEnum } from '../../enums/PaymentKey.enum';
import { StorageKeys } from '@enums/StorageKeys.enum';

import { PAYMENT_METHODS } from './PaymentData';

// MAKE PAYMENT FORM
// MakePayment MODEL check models
@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss'],
})
export class MakePaymentComponent implements OnDestroy {
  alertData: AlertModal = new AlertModal();
  back: string = '/';
  congressId: number = -1;
  currentSubscription: PaymentSubscription = new PaymentSubscription();
  data: MakePayment = new MakePayment();
  disabled: boolean = false;
  genericInvoicing: any = {};
  hotelData: HotelReservation = new HotelReservation();
  loading: boolean = true;
  multiple: boolean = true;
  name: string = '';
  paymentMethods: any[] = PAYMENT_METHODS;
  profile: Profile = new Profile();
  service: string = 'membership';
  subscriptionId: number = 0;
  suscriptions: PaymentSubscription[] = [];
  taxAlertData: AlertModal = new AlertModal();
  timer: any;
  total: number = 0;

  @ViewChild(CardComponent) cardComponent?: CardComponent;
  @ViewChild(BillFormComponent) billForm?: BillFormComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private user: UserService,
    private storage: Storage
  ) {}

  ngOnInit(): void {
    this.subscriptionId = Number(this.route.snapshot.params['id'] ?? -1);

    if (this.subscriptionId === -1) this.router.navigate(['']);

    const data = this.route.snapshot.data;

    if (data['service']) this.service = data['service'];
    if (data['back']) this.back = data['back'];
    if (data['type']) this.data.paymentType = data['type'];

    switch (this.data.paymentType) {
      case PaymentEnum.Congress:
        this.handleCongressData();
        break;

      case PaymentEnum.Course:
        this.handleCourse();

        break;

      case PaymentEnum.Hotel:
        this.handleHotel();

        break;

      case PaymentEnum.Webcast:
        this.multiple = false;

        this.handleWebcast();

        break;

      default:
        this.multiple = false;
        this.getSubscription();

        break;
    }
  }

  ngOnDestroy(): void {
    if (this.timer) clearTimeout(this.timer);
  }

  getSubscription() {
    this.api.callReq(
      {
        service: `portal/subscriptions/${this.service}`,
        method: 'get',
        params: {},
      },
      (response: any) => {
        this.suscriptions = response.data;

        this.suscriptions.map((suscription: PaymentSubscription) => {
          if (suscription.id === this.subscriptionId) {
            this.handleSoloSubscription(suscription);
          }
        });

        this.loading = false;
      },
      (error: any) => {
        console.error(error);

        this.alertData.data = {
          title: '¡Error!',
          content: error,
          className: AlertType.Danger,
        };

        this.loading = false;
      }
    );
  }

  handleSoloSubscription(suscription: PaymentSubscription) {
    this.multiple = false;
    this.currentSubscription = suscription;
    this.data.isZero = Number(this.currentSubscription.amount) === 0;
  }

  handleHotel() {
    const data: any = this.storage.getData(StorageKeys.Hotel);

    if (data['congress_id']) {
      this.congressId = Number(data['congress_id']);
    }

    this.hotelData.setData(data[PaymentEnum.Hotel]);

    if (data[PaymentEnum.Subscription]) {
      this.suscriptions.push(data[PaymentEnum.Subscription]);

      this.suscriptions.forEach((subscription) => {
        this.data.extras.push({
          subscription_id: subscription.id,
          quantity: 1,
        });
      });
    }

    // HOTEL INFO
    const hotel: any = this.data.extras[0];
    hotel['quantity'] = this.hotelData.nights;
    // All hotel data is in hotel['data']
    hotel['data'] = this.hotelData;
    hotel['adults'] = Number(this.hotelData.adults);
    hotel['children'] = Number(this.hotelData.children);

    this.total = this.getTotal(this.suscriptions);

    this.data.isZero = this.suscriptions.every(
      (subscription) => Number(subscription.amount) === 0
    );

    this.loading = false;
  }

  handleCourse() {
    const data = this.storage.getData(PaymentEnum.Course);

    this.suscriptions = data['susbcriptions'];

    if (data['congress_id']) {
      this.congressId = Number(data['congress_id']);
    }

    // OMIT MODALITY ON EXTRAS IT IS JUST FOR VISUAL INFORMATION
    this.suscriptions.forEach((subscription, ind) => {
      this.data.extras.push({
        subscription_id: subscription.id,
        quantity: 1,
      });
    });

    this.data.isZero = this.suscriptions.every(
      (subscription) => Number(subscription.amount) === 0
    );

    this.total = this.getTotal(this.suscriptions);
    this.loading = false;
  }

  handleWebcast() {
    const subscription: PaymentSubscription = this.storage.getData(
      PaymentEnum.Webcast
    );

    // this.handleSoloSubscription(subscription);
    this.multiple = true;
    this.suscriptions = [];
    this.suscriptions.push(subscription);

    this.total = this.getTotal(this.suscriptions);
    this.loading = false;
  }

  // Check congress-register.component.ts to check keys on handlePayment method
  handleCongressData() {
    const data: any = this.storage.getData(StorageKeys.Payment);

    if (data['congress_id']) {
      this.congressId = Number(data['congress_id']);
    }

    if (data[PaymentEnum.Subscription]) {
      this.suscriptions = data[PaymentEnum.Subscription];

      // OMIT MODALITY ON EXTRAS IT IS JUST FOR VISUAL INFORMATION
      this.suscriptions.forEach((subscription, ind) => {
        if (ind > 0) {
          this.data.extras.push({
            subscription_id: subscription.id,
            quantity: 1,
          });
        }
      });

      this.data.isZero = this.suscriptions.every(
        (subscription) => Number(subscription.amount) === 0
      );
    }

    if (data[CongressKeysEnum.Hotel]) {
      this.hotelData.setData(data[CongressKeysEnum.Hotel]);
      // HOTEL INFO
      const hotel: any = this.data.extras[this.data.extras.length - 1];

      hotel['quantity'] = this.hotelData.nights;
      // All hotel data is in hotel['data']
      hotel['data'] = this.hotelData;
      hotel['adults'] = Number(this.hotelData.adults);
      hotel['children'] = Number(this.hotelData.children);

      this.data.extras[this.data.extras.length - 1] = hotel;
    }

    this.total = this.getTotal(this.suscriptions);
    this.loading = false;
  }

  makePayment() {
    if (this.loading || this.disabled) return;

    if (this.data.isZero) {
      this.alertData.setActive(false);
      this.loading = true;
      this.pay();
      return;
    }

    if (!this.checkInvoice()) return;

    this.alertData.setActive(false);

    this.loading = true;

    // Call card component
    if (this.cardComponent) {
      // This emit event that onSuccessCard and onFailedCard catch the response
      this.cardComponent.getToken();
    } else {
      this.onFailedCard();
    }
  }

  onSuccessCard(id: string) {
    this.data.payment_method_id = id;
    this.pay();
  }

  onFailedCard() {
    this.loading = false;
  }

  pay() {
    let params: any = {};

    this.data.subscription_id = this.subscriptionId;
    this.data.save_invoicing = Number(this.data.save_invoicing);
    this.data.email = this.data.invoicing.email;
    params = this.data.getJSON();

    if (!this.data.isZero && this.data.invoice === 1) {
      params.invoicing = {
        payment_method: this.genericInvoicing.payment_method,
      };
    }

    // !! Used as payload parameter
    // console.log(params);
    // this.loading = false;
    // return;

    let service = '';

    switch (this.data.paymentType) {
      case PaymentEnum.Course:
        service = 'portal/payment/courses';
        params['congress_id'] = this.congressId;

        break;

      case PaymentEnum.Hotel:
        service = 'portal/payment/hotels';
        params['congress_id'] = this.congressId;

        break;

      case PaymentEnum.Webcast:
        service = 'portal/payment/webcasts';

        break;

      case PaymentEnum.Congress:
        service = 'portal/payment';
        params['congress_id'] = this.congressId;

        break;

      default:
        service = 'portal/payment';

        break;
    }

    //TODO handle for every data.paymentType service
    this.api.callReq(
      {
        service: service,
        method: 'post',
        params: params,
      },
      (response: any) => {
        const invoice = response.data.invoice;
        this.disabled = true;

        this.alertData.data = {
          title: '',
          content: '¡PAGO REALIZADO CON EXITO!',
          className: AlertType.Success,
        };

        this.storage.cleanStorage(StorageKeys.Payment);
        this.storage.setData(StorageKeys.Payment, {});

        if (this.data.isZero || (invoice && invoice.status === 'completed')) {
          this.getProfile(true);
        } else {
          if (this.data.invoice !== 1) {
            this.taxAlertData.data = {
              title: 'LA EMISIÓN DE SU FACTURA FALLO.',
              content: `<strong class="text-danger">Error: </strong> ${invoice.error}, puedes volver a intentar generar tu factura en esta liga:`,
              className: AlertType.Pink,
            };
          }

          this.getProfile();
        }

        this.loading = false;
      },
      (error: any) => {
        console.error(error);
        this.alertData.data = {
          title: '¡Error!',
          content: 'Pago rechazado',
          className: AlertType.Danger,
        };
        this.loading = false;
      }
    );
  }

  autoRedirect() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.router.navigate([`${this.back}`], {
        replaceUrl: true,
      });
    }, 3000);
  }

  checkInvoice(): boolean {
    if (this.data.invoice === 1) {
      if (!this.genericInvoicing.payment_method) {
        this.alertData.data = {
          title: '¡Error!',
          content: 'Selecciona la forma de pago.',
          className: AlertType.Danger,
        };

        return false;
      }

      return true;
    }

    if (this.billForm) {
      this.billForm.validate();
    }

    if (!this.data.invoicing.isValid() && !this.data.invoicing.vat_constancy) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Selecciona tu Constancia Fiscal',
        className: AlertType.Danger,
      };

      return false;
    }

    if (!this.data.invoicing.isValid()) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Llene todos los datos de facturación.',
        className: AlertType.Danger,
      };

      return false;
    }

    if (this.data.invoicing.email !== this.data.invoicing.confirm_email) {
      this.alertData.data = {
        title: '¡Error!',
        content: 'Los correos electrónicos no coinciden.',
        className: AlertType.Danger,
      };

      return false;
    }

    if (this.data.invoice === 2) {
      if (!this.data.invoicing.isVatNameValid()) {
        const msg = `El RFC de la persona ${
          this.data.invoicing.vat_type === 'moral' ? 'Moral' : 'Física'
        } debe tener ${
          this.data.invoicing.vat_type === 'moral' ? '12' : '13'
        } caracteres.`;

        this.alertData.data = {
          title: '¡Error!',
          content: msg,
          className: AlertType.Danger,
        };

        return false;
      }
    }

    return true;
  }

  // 2 = Si, 1 = No, 3 = Extranjero
  changeInvoiceRequired(id: number) {
    this.data.invoicing.isForeign = id === 3;
  }

  getTotal(subscription: PaymentSubscription[]) {
    const total: number = subscription.reduce(
      (summ: number, subscription: PaymentSubscription) => {
        return summ + Number(subscription.amount);
      },
      0
    );

    return total;
  }

  getProfile(redirect: boolean = false) {
    this.api.call('portal/me').subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.profile.setProfile(response.data);
          this.user.profile = response.data;
        }

        if (redirect) this.autoRedirect();
      },
      error: (error) => {
        console.error(error);

        if (redirect) {
          this.autoRedirect();
        }
      },
    });
  }
}
