export class InvoiceDATA {
  catalogs_cfdi_usage_id: number = 1;
  catalogs_regimen_id: number | null = 1;
  country: string = '';
  isForeign: boolean = false;
  email: string = '';
  confirm_email: string = '';
  payment_method: string = '28';
  vat_address: string = '';
  vat_name: string = '';
  vat_number: string = '';
  vat_type: string | null = null;
  vat_constancy: any | null = null;

  isValid(): boolean {
    if (this.isForeign) {
      return (
        this.vat_number.length > 0 &&
        this.vat_name.length > 0 &&
        this.country.length > 0
      );
    } else {
      return (
        this.vat_number.length > 0 &&
        this.vat_name.length > 0 &&
        this.vat_address.length > 0 &&
        this.vat_constancy
      );
    }
  }

  // RFC of moral is 12 characters long, physical is 13
  isVatNameValid(): boolean {
    if (this.isForeign) return this.vat_number.length > 0;

    return this.vat_type === 'moral'
      ? this.vat_number.length === 12
      : this.vat_number.length === 13;
  }

  setJSON(json: any) {
    this.catalogs_cfdi_usage_id = json.catalogs_cfdi_usage_id ?? 1;
    this.catalogs_regimen_id = json.catalogs_regimen_id ?? null;
    this.vat_address = json.vat_address ?? '';
    this.vat_name = json.vat_name ?? '';
    this.vat_number = json.vat_number ?? '';
    this.vat_type = json.vat_type ?? null;
    this.vat_constancy = json.vat_constancy ?? null;

    if (json.vat_country) {
      this.country = json.vat_country ?? '';
    }
  }

  getJSON() {
    if (this.isForeign) {
      return {
        vat_country: this.country,
        payment_method: this.payment_method,
        vat_name: this.vat_name,
        vat_number: this.vat_number,
      };
    } else
      return {
        vat_name: this.vat_name,
        vat_number: this.vat_number,
        vat_address: this.vat_address,
        vat_type: this.vat_type,
        vat_constancy: this.vat_constancy,
        catalogs_cfdi_usage_id: this.catalogs_cfdi_usage_id,
        catalogs_regimen_id: this.catalogs_regimen_id,
        payment_method: this.payment_method,
      };
  }
}

export class Invoice {
  id: number = 0;
  folio: string = '';
  created_at: string = '';
  invoiceable: number = 0;
  status: string = '';
  amount: string = '';
  type: string = '';
  subscription_type: string = '';
  concept: string = '';
  cfdi_xml_path: string = '';
  cfdi_pdf_path: string = '';
  error: string | null = null;
}
