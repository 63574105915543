import { Component, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { InvoiceDATA } from '@models/Invoice.model';
import { Profile } from '@models/Profile.model';
import { ApiService } from '@services/api.service';

interface Item {
  id: number;
  description: string;
}

@Component({
  selector: 'app-bill-form',
  templateUrl: './bill-form.component.html',
  styleUrls: ['./bill-form.component.scss'],
})
export class BillFormComponent {
  @Input() data: InvoiceDATA = new InvoiceDATA();
  @Input() isPayment: boolean = true;

  cfdiUssage: Item[] = [];
  confirmEmail: string = '';
  countries: Item[] = [];
  email: string = '';
  loading: boolean = true;
  profile: Profile = new Profile();
  saveData: boolean = false;
  taxRegimes: Item[] = [];
  validating: boolean = false;
  personTypes: any[] = [
    {
      id: 'physical',
      description: 'Física',
    },
    {
      id: 'moral',
      description: 'Moral',
    },
  ];
  paymentMethods: any[] = [
    {
      id: '28',
      description: 'Tarjeta de débito',
    },
    {
      id: '4',
      description: 'Tarjeta de crédito',
    },
  ];

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    forkJoin([
      this.api.call('portal/invoices/usages'),
      this.api.call('portal/me'),
      this.api.call('portal/invoices/countries'),
    ]).subscribe({
      next: ([cfdiUssage, profile, countries]) => {
        if (
          cfdiUssage.status === 200 &&
          profile.status === 200 &&
          countries.status === 200
        ) {
          this.cfdiUssage = cfdiUssage.data;
          this.profile.setData(profile.data);
          this.data.email = this.profile.email;
          this.data.confirm_email = this.profile.email;
          this.countries = countries.data;
        }

        this.data.setJSON(this.profile);

        if (profile.data.vat_country) this.data.isForeign = true;

        this.changeVatType(true);
        this.loading = false;
      },
      error: (error) => {
        console.error(error);

        this.loading = false;
      },
    });
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    if (files.length > 0) {
      const selectedFile = files[0];
      const fileName = selectedFile.name;

      this.convertToBase64AndUpload(fileName, selectedFile);
    }
  }

  convertToBase64AndUpload(name: string, file: File) {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result as string;
      this.data.vat_constancy = {
        content: base64String,
        name: name,
      };
    };

    reader.onerror = () => {
      console.error('Error al cargar el archivo.');
    };

    reader.readAsDataURL(file);
  }

  changeVatType(isInit: boolean = false) {
    this.loading = true;

    this.api
      .call(`portal/invoices/regimens?type=${this.data.vat_type}`)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.taxRegimes = response.data;
          if (!isInit) this.data.catalogs_regimen_id = this.taxRegimes[0].id;
        }

        this.loading = false;
      });
  }

  validate() {
    this.validating = true;
  }
}
