<div class="row" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <div class="col-12 mb-4" *ngIf="!data.isForeign">
    Tipo de Persona

    <span class="mt-2 required">
      <ng-select
        [items]="personTypes"
        bindLabel="description"
        bindValue="id"
        placeholder="Elija tipo"
        class="is-invalid"
        [(ngModel)]="data.vat_type"
        [clearable]="false"
        (change)="changeVatType()"
      ></ng-select>
    </span>
  </div>

  <div class="col-12 mb-4">
    RFC
    <span class="mt-2 mb-4 required">
      <input
        type="text"
        class="form-control input-amm"
        id="rfc"
        placeholder="RFC"
        [(ngModel)]="data.vat_number"
        minlength="12"
        maxlength="13"
        validationdirective
        [required]="true"
      />
    </span>

    Nombre o razón social (como aparece en su Constancia de situación fiscal)
    <span class="required">
      <input
        type="text"
        class="form-control input-amm"
        id="name"
        placeholder="Nombre o razón social"
        [(ngModel)]="data.vat_name"
        validationdirective
        [required]="true"
      />
    </span>
  </div>

  <div class="col-12 mb-4" *ngIf="data.isForeign">
    País

    <span class="mt-2 required">
      <ng-select
        [items]="countries"
        bindLabel="description"
        bindValue="id"
        placeholder="Elija un país"
        [(ngModel)]="data.country"
        [clearable]="false"
      ></ng-select>
    </span>
  </div>

  <div class="col-12 mb-4" *ngIf="!data.isForeign">
    Régimen Fiscal

    <span class="mt-2 required">
      <ng-select
        [items]="taxRegimes"
        bindLabel="description"
        bindValue="id"
        [(ngModel)]="data.catalogs_regimen_id"
        [clearable]="false"
        placeholder="Elija régimen"
        [disabled]="!data.vat_type"
      ></ng-select>
    </span>
  </div>

  <div class="col-12 mb-4" *ngIf="!data.isForeign">
    Código Postal

    <span class="mt-2 required">
      <input
        maxdigits
        [maxlength]="5"
        type="text"
        class="form-control input-amm"
        id="zipcode"
        placeholder="Código postal"
        [(ngModel)]="data.vat_address"
        validationdirective
        [required]="true"
      />
    </span>
  </div>

  <div
    class="col-12 mb-4 horizontally-container"
    *ngIf="!data.isForeign && isPayment"
  >
    <span class="mb-2">Uso de CFDI</span>

    <span class="required">
      <ng-select
        [items]="cfdiUssage"
        bindLabel="description"
        bindValue="id"
        [(ngModel)]="data.catalogs_cfdi_usage_id"
        [clearable]="false"
      ></ng-select>
    </span>
  </div>

  <div class="col-12 mb-4" *ngIf="isPayment">
    Forma de pago

    <span class="mt-2 required">
      <ng-select
        [items]="paymentMethods"
        bindLabel="description"
        bindValue="id"
        [(ngModel)]="data.payment_method"
        [clearable]="false"
      ></ng-select>
    </span>
  </div>

  <div class="col-12 mb-4 horizontally-container" *ngIf="!data.isForeign">
    <span class="mb-2">Constancia Fiscal</span>

    <div class="d-flex flex-column w-100 align-items-start">
      <span class="align-items-start required flex-row">
        <input
          #fileInput
          type="file"
          class="form-control input-amm"
          [ngClass]="{'is-invalid': validating && !data.vat_constancy}"
          (change)="onFileSelected($event)"
          accept="application/pdf"
          [multiple]="null"
        />

        <div *ngIf="data.vat_constancy" class="ps-4 my-auto ms-auto me-3">
          <a
            class="text-decoration-none"
            href="{{ data.vat_constancy }}"
            target="_blank"
          >
            Descargar
          </a>
        </div>
      </span>

      <div class="w-100 mt-2" *ngIf="validating && !data.vat_constancy">
        <p class="text-danger mb-0">
          * ¡Selecciona tu Constancia Fiscal!
        </p>
      </div>
    </div>
  </div>

  <div class="col-12 px-0" *ngIf="isPayment">
    <div class="row">
      <div class="col-12 horizontally-container mb-3">
        <span> Correo Electrónico </span>

        <span>
          Si quiere recibir Factura a otro correo distinto al de su cuenta,
          ingréselo aquí:
        </span>
      </div>

      <div class="col-12 mb-3">
        <input
          type="text"
          class="form-control input-amm"
          id="email"
          placeholder="Correo electrónico"
          [(ngModel)]="data.email"
        />
      </div>

      <div class="col-12 mb-3">
        <input
          type="text"
          class="form-control input-amm"
          id="email2"
          placeholder="Confirmar correo electrónico"
          [(ngModel)]="data.confirm_email"
        />
      </div>
    </div>
  </div>

  <div class="col-12">
    <p class="text-primary mb-0">* Datos obligatorios</p>
  </div>
</div>
