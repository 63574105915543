<div class="container py-4 px-lg-4" [ngClass]="{'loading': loading}">
  <app-loading-spinner
    class="loading-spinner"
    [loading]="loading"
  ></app-loading-spinner>

  <h2 class="mt-4 mb-5 text-center">Información Socios {{ environment.association.name }}</h2>

  <div class="row gy-4 mb-4">
    <div class="col-12">
      <h3>Educación Médica</h3>
    </div>

    <div class="col-12">
      Universidad

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm mt-2"
          [(ngModel)]="data.college_name"
          placeholder="Nombre"
          validationdirective
        />
      </span>
    </div>

    <div class="col-12">
      Reg. S.A.A.

      <span class="required mt-2">
        <input
          type="text"
          class="form-control input-amm"
          [(ngModel)]="data.college_register"
          placeholder="Nombre"
          validationdirective
        />
      </span>
    </div>

    <div class="col-12">
      Reg. D.G.P. En caso de ser extranjero Reg. Oficial de su país

      <input
        type="text"
        class="form-control input-amm"
        [(ngModel)]="data.college_foreinger"
        placeholder="Nombre"
      />
    </div>
  </div>

  <div class="row gy-4 mb-4">
    <div class="col-12 align-items-center">
      <small>
        <strong><span class="text-primary">*</span> Datos obligatorios</strong>
      </small>
    </div>

    <app-alert [data]="alertData"></app-alert>

    <div class="col-12">
      <button class="btn btn-primary btn-amm" (click)="submit()">
        GUARDAR CAMBIOS
        <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
      </button>
    </div>
  </div>
</div>
